<template>
  <aside class="aside" :class="className">
    <div
      class="aside__membership"
      data-tooltip="Вы член Лиги Студентов Республики Татарстан"
    >
      <img
        src="@/assets/Membership.svg"
        alt=""
        v-if="currentMembershipStatus === 10"
      />
    </div>

    <div class="aside__img">
      <Skeleton v-if="isInfoLoading" />
      <Skeleton v-else-if="isPictureLoading" />
      <img v-else :src="$store.state.dontSavedCrop ?? avatarSrc" alt="Аватар" />
      <a @click="openPopup"></a>
    </div>
    <Skeleton class="aside__username" v-if="isInfoLoading" />
    <div class="aside__username" v-else>
      {{
        user.last_name ? `${user.last_name} ${user.first_name}` : "Пользователь"
      }}
    </div>
    <div
      v-for="(item, index) in filteredNavigationLinks"
      :key="index"
      class="aside__btn"
      :class="[
        { tooltip: item.disabled },
        { 'aside__btn--small': index === 0 },
      ]"
      tooltip="Для того, чтобы воспользоваться
                             всем функционалом сайта
                             вам необходимо заполнить профиль"
    >
      <router-link
        :to="item.link"
        class="aside__links"
        active-class="active"
        :class="{ 'aside__links--loading': isFrontVersionLoading }"
      >
        <Skeleton v-if="isInfoLoading || isFrontVersionLoading" />
        <Button
          v-else
          aria-labelledby="notifications-label"
          :class="item.class"
          profile
          class="aside__link--btn"
          large
          :disabled="item.disabled || isFrontVersionLoading"
        >
          {{ item.text }}
        </Button>
      </router-link>
    </div>
  </aside>
</template>

<script>
import navigationLinks from "@/enums/navigationLinks";
import Button from "@/components/Blocks/Button";
import Skeleton from "@/components/Blocks/Skeleton";
import { getImg } from "@/utils/helpers";
import { mapGetters, mapState } from "vuex";
import store from "@/store";

export default {
  name: "Sidebar",
  components: { Skeleton, Button },
  props: ["avatar", "member", "isInfoLoading"],
  data() {
    return {
      navigationLinks,
      isPictureLoading: false,
      width: window.innerWidth,
    };
  },
  watch: {
    avatar() {
      if (this.isMobile) return;
      this.loadInfo();
    },
  },
  computed: {
    avatarSrc() {
      let avatar;
      if (this.serverAvatar) {
        if (this.hashMedia) {
          avatar = `data:image/png;base64,${this.serverAvatar}`;
        } else avatar = this.serverAvatar;
      } else avatar = require("@/assets/Person.svg");
      return avatar;
    },
    isMobile() {
      return this.width < 768;
    },
    filteredNavigationLinks() {
      let buffer = JSON.parse(JSON.stringify(this.navigationLinks));

      return buffer.map((item) => {
        if (item.meta === "disabled") {
          item.disabled = !this.disabledFields["isEducationFilled"];
        }
        return item;
      });
    },
    className() {
      return {
        "aside--member": this.member,
      };
    },
    ...mapState("user", ["user", "disabledFields", "currentMembershipStatus"]),
    ...mapState(["serverAvatar", "isFrontVersionLoading"]),
    ...mapGetters(["hashMedia"]),
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupAvatarTerms",
        isShown: true,
        props: {},
      });
    },
    loadInfo() {
      if (this.serverAvatar) return;
      if (this.avatar) {
        if (this.hashMedia) {
          getImg(this.avatar)
            .then((result) => {
              this.$store.commit("setServerAvatar", result);
            })
            .finally(() => {
              this.isPictureLoading = false;
            });
        } else this.$store.commit("setServerAvatar", this.avatar);
      }
    },
  },
  mounted() {
    if (this.isMobile) return;
    this.loadInfo();
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss">
.aside {
  max-width: 280px;
  position: relative;
  width: 100%;
  padding: 40px;
  flex-shrink: 0;
  align-self: flex-start;
  background-color: var(--background--main);
  border-radius: 10px;
  &__membership {
    display: none;
    position: absolute;
    right: 25px;
    top: 0;
  }
  &--member {
    .aside__membership {
      display: block;
    }
  }
  @include adaptive(tablet-big) {
    max-width: 200px;
    padding: 40px 30px;
  }
  @include adaptive(tablet-small) {
    display: none;
  }
  &__img {
    .skeleton-basic {
      border-radius: 50%;
    }
    position: relative;
    height: 200px;
    border-radius: 50%;
    background-color: var(--background--disabled);
    margin-bottom: 20px;
    @include adaptive(tablet-big) {
      height: 140px;
      width: 140px;
    }
    & img {
      @include full-absolute();
      object-fit: scale-down;
      border-radius: 50%;
      object-fit: cover;
      @include adaptive(tablet-big) {
        object-position: 50% 50%;
      }
    }
    & a {
      position: absolute;
      top: 90%;
      &:after {
        content: "\e91a";
        font-family: "icomoon";
        font-size: 1.8rem;
        color: var(--white);
        padding: 7px;
        border: 2px solid var(--white);
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        @include adaptive(tablet-big) {
          font-size: 1.2rem;
          padding: 6px;
        }
      }
      &:hover {
        &:after {
          background-color: var(--white);
          color: var(--text--color);
        }
      }
    }
  }
  &__username {
    font-size: 2.2rem;
    line-height: 2.7rem;
    font-weight: 500;
    color: var(--white);
    text-align: center;
    margin-bottom: 20px;
    &.skeleton-basic {
      width: 150px;
      height: 2.7rem;
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
  &__btn {
    display: flex;
    flex-direction: column;
    a {
      margin: 0 0 16px;
    }
    .skeleton-basic {
      height: 50px;
      border-radius: 8px;
    }
    &--small {
      a {
        display: inline-block;
        margin: 0 auto 16px auto;
      }
      .skeleton-basic {
        height: 26px;
        width: 100px;
      }
    }

    @include adaptive(tablet-big) {
      .skeleton-basic {
        height: 48px;
      }
      &--small {
        padding: 6px 12px;

        .skeleton-basic {
          height: 26px;
        }
      }
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    &--loading {
      pointer-events: none;
    }
  }
  a.active > button {
    background-color: var(--white);
    color: var(--background--main);
  }
}
</style>
