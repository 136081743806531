<template>
  <div class="popup-revoke-application">
    <div class="popup-revoke-application__text">
      <p>Вы уверены, что хотите <span>отозвать заявку?</span></p>
      <p class="popup-revoke-application__text--small">
        Вы можете отозвать свою заявку без опасений. Все данные сохранятся и Вы
        сможете повторно подать заявку в случае, если идёт этап подачи.
      </p>
    </div>
    <div class="popup-revoke-application__btn">
      <Button secondary @click="rollbackStatement" :loading="isRollbackProcess">
        Отказ от участия
      </Button>
      <Button @click="closePopup">Отмена</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";

export default {
  name: "PopupRevokeApplication",
  components: { Button },
  computed: {
    ...mapState(["currentPopup", "achievementsList"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      isRollbackProcess: false,
    };
  },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
    async rollbackStatement() {
      this.isRollbackProcess = true;
      this.$store.commit("setDeleteBtnLoading", true);
      await createRequest(requestConfigs.POSTRollbackStatement, {
        routerPayload: { id: this.currentPopup.props.id },
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Заявка успешно отозвана",
            type: "success",
          });
          this.$store.dispatch(
            "deleteFromStatementsList",
            this.currentPopup.props.id
          );
          this.$store.commit("setCurrentPopup", {
            isShown: false,
          });
          this.$store.commit("setDeleteBtnLoading", false);
          if (this.currentPopup.props.fromProject)
            this.$router.push({ name: "Events" });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.popup-revoke-application {
  &__text {
    margin-bottom: 16px;
    span {
      text-decoration: underline;
      font-weight: 700;
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
  }
}
</style>
