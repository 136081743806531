<template>
  <div class="popup-revoke-application">
    <div class="popup-revoke-application__text">
      <p>Вы обновили данные. Хотите подать заявку?</p>
    </div>
    <div class="popup-revoke-application__btn">
      <Button secondary @click="senStatement" :loading="isSending">
        Подать заявку
      </Button>
      <Button @click="closePopup">Отмена</Button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Button from "@/components/Blocks/Button.vue";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapState } from "vuex";
export default {
  name: "PopupSendStatement",
  components: { Button },
  data() {
    return {
      isSending: false,
    };
  },
  computed: {
    ...mapState(["currentPopup"]),
  },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", {
        isShown: false,
      });
    },
    async senStatement() {
      this.isSending = true;
      await createRequest(requestConfigs.POSTSendStatement, {
        routerPayload: { id: this.currentPopup.props.id },
      });
      this.isSending = false;
      this.closePopup();
      this.$router.push({ name: "Applications" });
    },
  },
};
</script>

<style lang="scss">
.popup-revoke-application {
  &__text {
    margin-bottom: 16px;
    span {
      text-decoration: underline;
      font-weight: 700;
    }
    &--small {
      margin-top: 10px;
      font-size: 16px;
      color: var(--banner--color);
    }
  }
  &__btn {
    display: flex;
    gap: 10px;
  }
}
</style>
